import * as Icons from "@securitas/icons/bootstrap";

import React from "react";

import styles from "./AppTile.module.scss";
import { UserAppInfo } from "@securitas/react-common-components/dist/services/user-service";

export const AppTile: React.FC<{ app: UserAppInfo }> = ({ app }) => {
  const Icon = Icons[app.icon];
  if (!Icon) return null;
  return (
    <a href={app.url} className={styles.app}>
      <Icon className={styles.icon} />
      <div className={styles.name}>{app.name}</div>
    </a>
  );
};
