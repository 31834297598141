import {
  useUINotification,
  useUserActions,
} from "@securitas/react-common-components";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { DefaultOptions, QueryClient, QueryClientProvider } from "react-query";

const devTools =
  process.env.NODE_ENV !== "production"
    ? React.createElement(
        require("react-query/devtools").ReactQueryDevtools,
        {}
      )
    : null;

interface QueryErrorHandler {
  (e: any): void;
}

const createClientOptions = (
  errorHandler?: QueryErrorHandler,
  useErrorBoundary?: (error: any) => boolean
): DefaultOptions => ({
  queries: {
    //default query options go here
    staleTime: /* 8 HOURS */ 8 * 3600 * 1000,
    retry: false,
    onError: errorHandler,
    useErrorBoundary,
  },
  mutations: {
    //default mutation options go here
    onError: errorHandler,
  },
});

const isResponse = (e: any): e is Response => {
  return !!e.status && e.statusText;
};

const isNotLoggedIn = (e: Response) => e.status === 401;
const isNotAuthorized = (e: Response) => e.status === 403;
const isServerError = (e: Response) => e.status >= 500 && e.status <= 599;
const isMethodNotImplemented = (e: Response) => e.status === 501;
const isBusinessError = (e: Response) =>
  e.status >= 400 && e.status <= 499 && !isNotAuthorized(e);

export const AppQueryClientProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { login } = useUserActions();
  const { error } = useUINotification();
  const [client] = useState(
    () => new QueryClient({ defaultOptions: createClientOptions() })
  );

  useEffect(() => {
    //Global error handler
    const errorHandler = (e: any) => {
      debugger;
      if (isResponse(e)) {
        if (isNotLoggedIn(e)) {
          login();
        } else if (isBusinessError(e)) {
          //handle the special case where the error is thrown from
          //the cockpit API (different kind of error...)
          if (!e.clone || !e.json) {
            error("messages.generic-error");
          } else
            e.clone()
              .json()
              .catch((e) => {
                error("messages.generic-error", {
                  error: e,
                });
              })
              .then((data) =>
                error("messages.generic-error", {
                  error: data,
                })
              );
        }
      }
    };
    const useErrorBoundary = (e: any) => {
      //fixme:
      debugger;
      return (
        (isServerError(e) || isNotAuthorized(e)) && !isMethodNotImplemented(e)
      );
    };
    client.setDefaultOptions(
      createClientOptions(errorHandler, useErrorBoundary)
    );
  }, [client, login, error]);

  return process.env.NODE_ENV !== "production" ? (
    <QueryClientProvider client={client}>
      {children}
      {devTools}
    </QueryClientProvider>
  ) : (
    <QueryClientProvider client={client}>{children}</QueryClientProvider>
  );
};
