/*
 * 2020-2021 (C) - OneOverZero AG - MR,FG
 */
import { createInstance } from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { webpackJsonLoader } from "./i18next-webpack-plugin";

const debug = !(
  process.env.REACT_APP_ENVIRONMENT_NAME === "prod" ||
  process.env.REACT_APP_ENVIRONMENT_NAME === "test"
);

const instance = () => {
  const newInstance = createInstance()
    .use(initReactI18next) // bind react-i18next to the instance
    .use(I18nextBrowserLanguageDetector)
    .use(webpackJsonLoader);

  newInstance.init({
    supportedLngs: ["de", "fr", "en", "it"],
    fallbackLng: "de",
    debug,
    initImmediate: false,
    ns: ["main"],
    defaultNS: "main",
    fallbackNS: "main",

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "ul", "li", "a"],
      useSuspense: false,
      // react i18next special options (optional)
      // override if needed - omit if ok with defaults
      /*
        bindI18n: 'languageChanged',
        bindI18nStore: '',
        transEmptyNodeValue: '',

      */
    },

    /**
     * I18n language detector. @See https://github.com/i18next/i18next-browser-languageDetector
     */
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "securitas-one-language",
      caches: ["localStorage"],
    },
  });
  return newInstance;
};

export default instance;
