import React, { useMemo } from "react";
import { PageRoutes } from "./PageRoutes";
import { BrowserRouter } from "react-router-dom";
import i18n from "./i18n/i18n";
import { I18nextProvider } from "react-i18next";
import {
  SecuritasApplication,
  PartialUIState,
  ServiceContextProvider,
  UIContextProvider,
  LoggedInOnly,
} from "@securitas/react-common-components";

import { Configuration } from "./configuration";

import { AppQueryClientProvider } from "./queries/AppQueryClientProvider";

const uiState: PartialUIState = {};

const App: React.FC = () => {
  const i18nInstance = useMemo(() => i18n(), []);
  return (
    <I18nextProvider i18n={i18nInstance}>
      <BrowserRouter basename={Configuration.baseUrl}>
        <UIContextProvider state={uiState}>
          <ServiceContextProvider>
            <SecuritasApplication>
              <AppQueryClientProvider>
                <LoggedInOnly>
                  <PageRoutes />
                </LoggedInOnly>
              </AppQueryClientProvider>
            </SecuritasApplication>
          </ServiceContextProvider>
        </UIContextProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
};
export default App;
