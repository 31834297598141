import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";

export const PageRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="/" replace />} />
      <Route index element={<HomePage />} />
    </Routes>
  );
};
