import React from "react";
import {
  Col,
  Row,
  useUserSelector,
  UserSelectors,
  SecuritasPage,
  SecuritasAppBar,
  SecuritasContent,
} from "@securitas/react-common-components";
import { Configuration } from "../configuration";
import { AppTile } from "./AppTile";

const ConnectedHomePage: React.FC = () => {
  const userApps = useUserSelector(UserSelectors.applications);

  if (!userApps) return null;

  return (
    <SecuritasPage>
      <SecuritasAppBar
        appName={Configuration.appName}
        appLogo={Configuration.appLogo}
      ></SecuritasAppBar>
      <SecuritasContent>
        <div className="h-100 d-flex align-items-center justify-content-center">
          <Row className="justify-content-center">
            {userApps.map((app) => (
              <Col key={app.url} xs="auto" className="m-4">
                <AppTile app={app} />
              </Col>
            ))}
          </Row>
        </div>
      </SecuritasContent>
    </SecuritasPage>
  );
};

export default ConnectedHomePage;
