/*
 * 2020-2021 (C) - OneOverZero AG - MR,FG
 */
// See https://www.i18next.com/misc/creating-own-plugins

import { BackendModule } from "i18next";

/**
 * Plugin that leverages on webpack dynamic import to
 */
export const webpackJsonLoader: BackendModule<void> = {
  type: "backend",

  //init service
  init: (services, backendOptions, i18nextOptions) => {},

  //reads a namespace
  read: (language, namespace, callback) => {
    const languagePart = language.split("-")[0];

    //@see https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
    import(
      /* webpackChunkName: "locale" */
      /* webpackPrefetch: true */
      /* webpackExport: ["default"] */
      `./locales/${languagePart}/${namespace}.json`
    )
      .then((data) => {
        callback(null, data.default);
      })
      .catch((e) => callback(e, false));
  },

  //saves a missing translation
  create: (languages, namespace, key) => {
    //ReportingService.missingI18nKey(languages.join(','), namespace, key);
  },
};
